import React from "react"
import { graphql, Link } from "gatsby"
import VideoList from "../../components/VideoList"

const FilmsGratuits = props => {
    const url = "https://cinetimes.org/collections/films-gratuits/"
    const edges = props.data.allVideo.edges
    const title = `${edges.length}Films gratuits`
    const description = `Sélection de ${edges.length} films en Français, à voir gratuitement en streaming. Cette liste est automatiquement mise à jour pour refléter les modifications, ajouts et retraits. Les films viennent principalement de archive.org, mais aussi de YouTube, Dailymotion et Vimeo.`
    return (
        <VideoList url={url} title={title} description={description} edges={edges} similar={
            <>
                <Link to="/blog/films-gratuits/">Films gratuits</Link>, <Link to="/collections/dessins-animes-gratuits/">Dessins animés gratuits</Link>, <Link to="/collections/documentaires-gratuits/">Documentaires gratuits</Link>
            </>
        }/>
    )
}

export default FilmsGratuits


  
export const query = graphql`
  query {
    allVideo(filter: {video_taken_down: {eq: false}, movie_genres: {nin: ["Animation"]}}, sort: {fields: video_id, order: DESC} ) {
        edges {
          node {
            video_id
            video_subtitles
            video_audio
            video_title
            translation_synopsis
            video_taken_down
            translation_title
            translation_slug
            movie_genres
            translation_poster {
                url
            }
          }
        }
    }
  }
`